import React, { PropsWithChildren, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, Hidden } from "@mui/material";
import FullWidthDivider from "../FullWidthDivider";
import { useStickyBlock } from "../../../hooks/hooks";

const useStyles = makeStyles((theme) => ({
  subHeaderContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(1),
    },
  },
  subHeader: {
    padding: theme.spacing(1),
    margin: `0px !important`,
  },
  sticky: {
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      left: 0,
      right: 0,
      top: "0",
      zIndex: "5000",
      background: theme.palette.background.paper,
      boxShadow: "0px 1px 7px 0 rgb(0 0 0 / 20%)",
      padding: "10px !important",
    },
  },
}));

export type PageHeaderProps = {
  title?: string | null;
  subtitle?: string | null;
  actions?: JSX.Element;
  mobileMenuButton?: React.ReactNode;
  className?: string;
  isSticky?: boolean;
  isExtras?: boolean;
};

export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  actions,
  mobileMenuButton,
  className,
  isSticky,
  isExtras,
}: PropsWithChildren<PageHeaderProps>) => {
  const classes = useStyles();
  const stickyRef = useRef<HTMLHRElement>(null);
  const stickyState = useStickyBlock(stickyRef);

  return (
    <div className={classes.subHeaderContainer} ref={stickyRef}>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"space-between"}
        className={className}
        spacing={2}
      >
        <Grid
          item
          sm
          xs={12}
          className={isSticky && stickyState ? `${classes.sticky}` : ""}
        >
          <div className={classes.subHeader}>
            <Grid container xs={12}>
              <Grid item xs>
                {title && <Typography variant="h1">{title}</Typography>}
                {subtitle && <Typography variant="h3">{subtitle}</Typography>}
              </Grid>
              {mobileMenuButton && (
                <Hidden mdUp>
                  <Grid item xs={1}>
                    {mobileMenuButton}
                  </Grid>
                </Hidden>
              )}
            </Grid>
          </div>
          {actions && (
            <Hidden mdUp>
              <Grid item sm xs={12}>
                {actions}
              </Grid>
            </Hidden>
          )}
        </Grid>
        {actions && !isExtras && (
          <Hidden smUp>
            <Grid item xs={12}>
              <FullWidthDivider />
            </Grid>
          </Hidden>
        )}
        {actions && !isExtras && (
          <Grid item sm xs={12}>
            {actions}
          </Grid>
        )}
      </Grid>
    </div>
  );
};
export default PageHeader;
