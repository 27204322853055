import React from "react";
import { Banner } from "../../components/SemanticTheme/Banner";
import { updateAbsoluteLink } from "../../components/Utils";
import { BannerProps } from "../../utils/common";
import { checkTradingTabExpiry } from "../../utils";
import { ParsedUrlQuery } from "querystring";
import { filterXSS } from "xss";

type TradingTab = {
  url: string | null;
  line1Label: string;
  line2Label: string;
  line3Label: string;
  line4Label: string;
  tabFontSizeLine1: number;
  tabFontSizeLine2: number;
  tabFontSizeLine3: number;
  tabFontSizeLine4: number;
};

interface BannerPropsWithExp {
  expImgOverLay?: boolean;
  isBookingWidget?: boolean;
  queryData?: ParsedUrlQuery;
  bannerImageURL?: string;
  additionalProps?: { [key in string]: string | boolean };
  H1andSubtitleChangeToShowWhich?: boolean;
  mobileYellowBannerDeskandTab?: boolean;
  increaseHeroHeight?: boolean;
  moveSearchAndBannerBelowHero?: boolean;
  megaCompactBanner?: boolean;
}

const BannerComponent: React.FC<BannerProps & BannerPropsWithExp> = (
  props,
): JSX.Element => {
  const bannerTitleFromQueryString = filterXSS(
    (props?.queryData?.title as string) || "",
  );
  const bannerTitle = bannerTitleFromQueryString || props.BannerTitle;

  const imgUrl = props?.bannerImageURL;

  const tradingTab: TradingTab = checkTradingTabExpiry(props) && {
    url:
      updateAbsoluteLink(props?.tradingTabURL?.data?.attributes?.PageRoute) ??
      null,
    line1Label: props?.tabTextLine1,
    line2Label: props?.tabTextLine2,
    line3Label: props?.tabTextLine3,
    line4Label: props?.tabTextLine4,
    tabFontSizeLine1: props?.tabFontSizeLine1,
    tabFontSizeLine2: props?.tabFontSizeLine2,
    tabFontSizeLine3: props?.tabFontSizeLine3,
    tabFontSizeLine4: props?.tabFontSizeLine4,
  };
  const isStickyBanner = props?.stickyBanner ? true : false;
  const increaseHeroHeight =
    !!props?.additionalProps?.increaseHeroHeight && !!imgUrl;
  const moveSearchAndBannerBelowHero =
    !!props?.additionalProps?.moveSearchAndBannerBelowHero && !!imgUrl;

  return (
    <>
      <Banner
        theme="dark"
        imgUrl={imgUrl}
        title={
          props?.additionalProps?.H1andSubtitleChangeToShowWhich
            ? "A staycation you can trust"
            : bannerTitle
        }
        subTitle={
          props?.additionalProps?.H1andSubtitleChangeToShowWhich
            ? "We're a Which? recommended provider for UK Holiday Parks July 2024"
            : props?.BannerSubTitle
        }
        videoUrl={props?.BannerVideoURL}
        ctaLabel={props?.BannerCTALabel}
        ctaUrl={props?.bannerVideoCallToActionRef}
        bookingWidget={props?.bannerComponent}
        autoplayVideo={props?.AutoplayVideo}
        dontDisplayTitleAsHeading={props?.DontuseH1tagsinpagebanner}
        compactView={props?.CompactBanner}
        tradingTab={
          props?.additionalProps?.mobileYellowBannerDeskandTab
            ? null
            : tradingTab
        }
        tradingTabBgColor={
          props?.tabBackgroundColour?.data?.attributes?.ColorCode
        }
        tradingTabPosition={props?.tabAlignment}
        isBannerStickyBar={isStickyBanner || false}
        isBookingWidget={Boolean(props?.isBookingWidget)}
        increaseHeroHeight={increaseHeroHeight}
        moveSearchAndBannerBelowHero={moveSearchAndBannerBelowHero}
        megaCompactView={props?.megaCompactBanner}
      >
        {props?.children}
      </Banner>
    </>
  );
};

export default BannerComponent;
