import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { Container, Typography } from "@mui/material";
import { Link } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 10,
    order: 1,
    marginTop: "auto",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mobileYellowBannerRoot: {
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  tabContainer: {
    marginBottom: 0,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  tab: {
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(1, 2, 2.5, 2),
    textAlign: "center",
    borderRadius: 10,
    marginBottom: -15,
    "& p": {
      margin: 0,
      lineHeight: "21px",
    },
  },
  mobileYellowBanner: {
    marginBottom: 0,
    borderRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    paddingBottom: theme.spacing(1),
  },
  tabOnly: {
    padding: theme.spacing(1, 2, 1, 2),
    marginBottom: -5,
  },
  label: {
    fontSize: 16,
    textTransform: "uppercase",
  },
  tabLink: {
    position: "relative",
    display: "inline-block",
    color: theme.palette.text.primary,
    textDecorationColor: theme.palette.text.primary,
  },
  addSearchFormMargin: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  moveBannerBelowHero: {
    borderRadius: 0,
  },
}));

type TradingTab = {
  url: string;
  tradingTabInMobile: JSX.Element;
};

type MobileTradingTabProps = {
  className?: string;
  tradingTab?: TradingTab;
  tradingTabInMobile?: boolean;
  isBookingWidget?: boolean;
  mobileYellowBannerDeskandTab?: boolean;
  moveSearchAndBannerBelowHero?: boolean;
};

export const MobileTradingTab: React.FC<
  PropsWithChildren<MobileTradingTabProps>
> = ({
  className,
  tradingTab,
  isBookingWidget,
  mobileYellowBannerDeskandTab,
  moveSearchAndBannerBelowHero,
}: PropsWithChildren<MobileTradingTabProps>): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={classnames(classes.root, className, {
        [classes.mobileYellowBannerRoot]: mobileYellowBannerDeskandTab,
      })}
    >
      <Container className={classes.tabContainer}>
        {tradingTab && (
          <div
            className={classnames(classes.tab, {
              [classes.tabOnly]: !isBookingWidget,
              [classes.mobileYellowBanner]: mobileYellowBannerDeskandTab,
              [classes.moveBannerBelowHero]: moveSearchAndBannerBelowHero,
            })}
          >
            {tradingTab.url ? (
              <Link className={classes.tabLink} href={tradingTab.url}>
                <Typography component="div" className={classes.label}>
                  {tradingTab.tradingTabInMobile}
                </Typography>
              </Link>
            ) : (
              <Link href={tradingTab.url} underline="none">
                <Typography component="div" className={classes.label}>
                  {tradingTab.tradingTabInMobile}
                </Typography>
              </Link>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};
