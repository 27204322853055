import React, { PropsWithChildren } from "react";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 11,
    order: 2,
    margin: "auto 0 30px",
    marginTop: 0,
    minHeight: 88,
    [theme.breakpoints.down("md")]: {
      marginBottom: -10,
      minHeight: 280,
    },
  },
  bannerWidgetRoot: {
    marginTop: 0,
  },
  container: {
    [theme.breakpoints.down("md")]: {
      padding: 0,
      margin: 0,
    },
  },
  paperRoot: {
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      minHeight: 280,
    },
  },
}));

interface BannerWidgetContainerProps {
  className?: string;
  mobileYellowBannerDeskandTab?: boolean;
}

export const BannerWidgetContainer: React.FC<
  PropsWithChildren<BannerWidgetContainerProps>
> = ({
  className,
  children,
  mobileYellowBannerDeskandTab,
}: PropsWithChildren<BannerWidgetContainerProps>): JSX.Element => {
  const classes = useStyles();

  return (
    <div
      className={classnames(classes.root, className, {
        [classes.bannerWidgetRoot]: mobileYellowBannerDeskandTab,
      })}
    >
      <>{children}</>
    </div>
  );
};
